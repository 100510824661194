const rect = (el, normalise = false) => {
  const rect = el.getBoundingClientRect();

  if (!normalise) {
    return rect;
  }

  const visible =
    Math.min(
      window.innerHeight,
      Math.max(
        0,
        Math.min(window.innerHeight, rect.bottom) - Math.max(0, rect.top)
      )
    ) / rect.height;

  return {
    width: rect.width / window.innerWidth,
    height: rect.height / window.innerHeight,
    top: rect.top / window.innerHeight,
    left: rect.left / window.innerWidth,
    right: rect.right / window.innerWidth,
    bottom: rect.bottom / window.innerHeight,
    midX: (rect.left + rect.width / 2) / window.innerWidth,
    midY: (rect.top + rect.height / 2) / window.innerHeight,
    visible,
    isVisible: !!visible,
  };
};

export default (ctx, inject) => {
  inject('rect', rect);
};
