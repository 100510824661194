import GET_GLOBALS_QUERY from '~/graphql/queries/GetGlobals';
import { collapseFieldArray } from '~/util';

export const state = () => ({
  address: '',
  contactCtaPanel: [],
  disclaimer: '',
  onboardingSection: { heading: '', body: '' },
  phoneNumber: '',
  socialChannels: [],
  testimonials: [],
  video: null,
  videoThumbnail: null,
});

export const getters = {
  address: (state) => state.address,
  contactCtaPanel: (state) => state.contactCtaPanel,
  disclaimer: (state) => state.disclaimer,
  onboardingSection: (state) => state.onboardingSection,
  phoneNumber: (state) => state.phoneNumber,
  socialChannels: (state) => state.socialChannels,
  testimonials: (state) => state.testimonials,
  video: (state) => state.video,
  videoThumbnail: (state) => state.videoThumbnail,
};

export const mutations = {
  address(state, value) {
    state.address = value;
  },
  contactCtaPanel(state, value) {
    state.contactCtaPanel = value;
  },
  disclaimer(state, value) {
    state.disclaimer = value;
  },
  onboardingSection(state, value) {
    state.onboardingSection = value;
  },
  phoneNumber(state, value) {
    state.phoneNumber = value;
  },
  socialChannels(state, value) {
    state.socialChannels = value;
  },
  testimonials(state, value) {
    state.testimonials = value;
  },
  video(state, value) {
    state.video = value;
  },
  videoThumbnail(state, value) {
    state.videoThumbnail = value;
  },
};

export const actions = {
  async getGlobalsData({ commit }) {
    if (this.app.apolloProvider.defaultClient) {
      await this.app.apolloProvider.defaultClient
        .query({
          query: GET_GLOBALS_QUERY,
        })
        .then(({ data }) => {
          const { contact, footer, onboardingSection, video } = data;
          // Contact
          commit('phoneNumber', contact.phoneNumber);
          commit('contactCtaPanel', collapseFieldArray(contact.ctaPanel));

          // Footer
          commit('address', footer.address);
          commit('disclaimer', footer.disclaimer);
          commit('socialChannels', footer.socialChannels);
          commit('testimonials', footer.testimonials);

          // Onboarding Section
          commit(
            'onboardingSection',
            collapseFieldArray(onboardingSection.content)
          );

          // Video
          commit('video', collapseFieldArray(video.video));
          commit('videoThumbnail', collapseFieldArray(video.videoThumbnail));
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e.message);
        });
    }
  },
};
