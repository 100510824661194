import { isArray, isObject } from 'lodash';
import cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';

export function collapseFieldArray(field) {
  return field?.length ? field[0] : null;
}

export function isIe11() {
  return window && window.navigator.appVersion.includes('Trident/');
}

export function paramsToQueryString(params) {
  let query = '';

  if (isArray(params)) {
    query = params.reduce((acc, { key, value }, index) => {
      return `${acc}${index ? '&' : ''}${key}=${value}`;
    }, '?');
  } else if (isObject(params)) {
    query = Object.keys(params).reduce((acc, key, index) => {
      return `${acc}${index ? '&' : ''}${key}=${params[key]}`;
    }, '?');
  }

  return query;
}

export function queryStringToObject(query) {
  const result = {};
  const urlParams = new URLSearchParams(query);
  for (const entry of urlParams.entries()) {
    const [key, value] = entry;
    result[key] = value;
  }
  return result;
}

export function transformHomeData({ entry, app }) {
  entry.headerContent = collapseFieldArray(entry.headerContent);
  entry.intro = collapseFieldArray(entry.intro);
  entry.steps = collapseFieldArray(entry.steps);

  const { charities, estate, family, pets } = entry.willFeatures[0];

  entry.willFeatures = [];

  entry.willFeatures.push({
    label: app.i18n.t('common.titles.family'),
    slug: 'family',
    title: family[0].title,
    heading: family[0].heading,
    body: family[0].body,
    image: family[0].image[0],
    imageType: 'guardians',
    classes: 'bg-salmon-100 text-salmon-300 selection-bg-salmon-100',
    buttonClasses: 'text-salmon-100 selection-bg-salmon-100',
  });

  entry.willFeatures.push({
    label: app.i18n.t('common.titles.estate'),
    slug: 'estate',
    title: estate[0].title,
    heading: estate[0].heading,
    body: estate[0].body,
    image: estate[0].image[0],
    imageType: 'gift',
    classes: 'bg-orange-100 text-orange-200 selection-bg-orange-100',
    buttonClasses: 'text-orange-100 selection-bg-orange-100',
  });

  entry.willFeatures.push({
    label: app.i18n.t('common.titles.pets'),
    slug: 'pets',
    title: pets[0].title,
    heading: pets[0].heading,
    body: pets[0].body,
    image: pets[0].image[0],
    imageType: 'pets',
    classes: 'bg-lavender-100 text-lavender-200 selection-bg-lavender-100',
    buttonClasses: 'text-lavender-100 selection-bg-lavender-100',
  });

  entry.willFeatures.push({
    label: app.i18n.t('common.titles.charities'),
    slug: 'charities',
    title: charities[0].title,
    heading: charities[0].heading,
    body: charities[0].body,
    image: charities[0].image[0],
    imageType: 'charity',
    classes: ['bg-babyblue-100 text-babyblue-200 selection-bg-babyblue-100'],
    buttonClasses: 'text-babyblue-100 selection-bg-babyblue-100',
  });

  if (entry.products) {
    const { will, poa, cremation, probate } = entry.products[0];
    entry.products = [];

    entry.products.push({
      label: app.i18n.t('common.titles.will'),
      slug: 'will',
      title: will[0].title,
      heading: will[0].heading,
      body: will[0].body,
      image: will[0].image[0],
      getStartedPageUrl: will[0].getStartedPageUrl,
      imageType: 'will',
      classes: 'bg-teal-100 text-teal-200 selection-bg-teal-100',
      buttonClasses: 'text-teal-100 selection-bg-teal-100',
    });

    entry.products.push({
      label: app.i18n.t('common.titles.powerOfAttorney'),
      slug: 'poa',
      title: poa[0].title,
      heading: poa[0].heading,
      body: poa[0].body,
      image: poa[0].image[0],
      getStartedPageUrl: poa[0].getStartedPageUrl,
      imageType: 'poa',
      classes: 'bg-salmon-100 text-salmon-300 selection-bg-salmon-100',
      buttonClasses: 'text-salmon-100 selection-bg-salmon-100',
    });

    entry.products.push({
      label: app.i18n.t('common.titles.cremation'),
      slug: 'cremation',
      title: cremation[0].title,
      heading: cremation[0].heading,
      body: cremation[0].body,
      image: cremation[0].image[0],
      getStartedPageUrl: cremation[0].getStartedPageUrl,
      imageType: 'cremation',
      classes: 'bg-babyblue-100 text-babyblue-300 selection-bg-babyblue-100',
      buttonClasses: 'text-babyblue-100 selection-bg-babyblue-100',
    });

    entry.products.push({
      label: app.i18n.t('common.titles.probate'),
      slug: 'probate',
      title: probate[0].title,
      heading: probate[0].heading,
      body: probate[0].body,
      image: probate[0].image[0],
      getStartedPageUrl: probate[0].getStartedPageUrl,
      imageType: 'probate',
      classes: 'bg-lavender-100 text-lavender-300 selection-bg-lavender-100',
      buttonClasses: 'text-lavender-100 selection-bg-lavender-100',
    });
  }

  return { entry };
}

export function transformLegalAdviceData({ entry }) {
  entry.headerContent = collapseFieldArray(entry.headerContent);
  entry.steps = collapseFieldArray(entry.steps);

  return { entry };
}

export function cloneMetaFromCraftSeomatic(seomaticMetas, keyToClone, newKey) {
  const metaToClone = seomaticMetas.find((meta) => {
    return meta.property === keyToClone || meta.name === keyToClone;
  });

  if (!metaToClone) {
    return [];
  }

  return [
    {
      ...metaToClone,
      ...(metaToClone.name ? { name: newKey } : undefined),
      ...(metaToClone.property ? { property: newKey } : undefined),
      hid: newKey,
    },
  ];
}

export function transformSeomaticMeta({ seomatic }) {
  // From https://github.com/ben-rogerson/nuxt-seomatic-meta/blob/master/lib/plugin.js
  // eslint-disable-next-line no-console
  if (!seomatic) {
    console.error('No SEOmatic data returned from Craft');
  }

  const {
    metaTitleContainer: {
      title: { title },
    },
    metaTagContainer,
    metaLinkContainer,
    metaScriptContainer,
    metaJsonLdContainer,
  } = Object.entries(seomatic).reduce((acc, [key, value]) => {
    if (key !== '__typename') {
      acc[key] = JSON.parse(value);
    }

    return acc;
  }, {});

  const meta = metaTagContainer
    ? Object.values(metaTagContainer).reduce(
        (flat, next) => flat.concat(next),
        []
      )
    : null;

  const link = metaLinkContainer
    ? Object.values(metaLinkContainer).reduce(
        (flat, next) => flat.concat(next),
        []
      )
    : null;

  const metaScripts = metaScriptContainer
    ? Object.values(metaScriptContainer).map(({ script }) => ({
        innerHTML: script,
      }))
    : [];

  const jsonLd = metaJsonLdContainer
    ? Object.values(metaJsonLdContainer).map((value) => ({
        type: 'application/ld+json',
        innerHTML: JSON.stringify(value),
      }))
    : [];

  const script = [...metaScripts, ...jsonLd];

  return {
    ...(title && { title }),
    ...(meta && { meta }),
    ...(link && { link }),
    script,
    __dangerouslyDisableSanitizers: ['script'],
  };
}

export function hexToRgb(hex) {
  // From https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function textColorFromBg(hex) {
  // From https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
  const bg = hexToRgb(hex);
  return bg.r * 0.299 + bg.g * 0.587 + bg.b * 0.114 > 186 ? '#000' : '#FFF';
}

export function jsonToBase64(json) {
  return Buffer.from(JSON.stringify(json)).toString('base64');
}

export function base64ToJson(base64) {
  return JSON.parse(Buffer.from(base64, 'base64').toString('ascii'));
}

export function isPromoActive(startDate, endDate) {
  const now = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  return now > start && now < end;
}

export function getAnonymousProfile() {
  const anonymousProfileBase64 = cookies.get(
    process.env.ANONYMOUS_PROFILE_COOKIE_NAME || 'anonymousProfile'
  );
  if (!anonymousProfileBase64 || anonymousProfileBase64.trim().length === 0) {
    return null;
  }
  try {
    return base64ToJson(anonymousProfileBase64);
  } catch (e) {
    console.error(e.message);
  }
  return null;
}

export function saveAnonymousProfile({ utm, couponCode }) {
  const existingProfile = getAnonymousProfile() || {};

  cookies.set(
    process.env.ANONYMOUS_PROFILE_COOKIE_NAME || 'anonymousProfile',
    jsonToBase64({
      id: existingProfile.id || cookies.get('launchdarkly_id') || uuid(),
      utm: utm || existingProfile.utm || {},
      referral_coupon: couponCode || existingProfile.referral_coupon,
    }),
    {
      path: '/',
      domain: '.safewill.com',
      expires: 14,
    }
  );
}

export function getFullName(firstName, lastName) {
  const fullName = firstName.trim() + ' ' + lastName.trim();
  return fullName.trim();
}
