import { collapseFieldArray } from '~/util';

export const state = () => ({
  bannerLogo: null,
  bannerText: '',
});

export const getters = {
  bannerLogo: (state) => state.bannerLogo,
  bannerText: (state) => state.bannerText,
};

export const mutations = {
  bannerLogo(state, value) {
    state.bannerLogo = value;
  },
  bannerText(state, value) {
    state.bannerText = value;
  },
};

export const actions = {
  setBannerLogo({ commit }, value) {
    commit('bannerLogo', collapseFieldArray(value));
  },
  setBannerText({ commit }, value) {
    commit('bannerText', value);
  },
};
